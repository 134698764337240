import React from "react";
import "./HomeHeader.css";

function HomeHeader() {
  return (
    <div className="background">
      <div className="home-header-component ">
        <h1>Creamy Scoops</h1>
        <a
          href={sessionStorage.getItem("featureFlag") ? "menu" : "order"}
          className="order-now-button"
        >
          {sessionStorage.getItem("featureFlag") ? "View Menu" : "Order Now"}
        </a>
        <a href="/plan-event" className="order-now-button">
          Plan an Event With Us!
        </a>
      </div>
    </div>
  );
}

export default HomeHeader;
