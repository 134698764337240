import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

function MessageModal({
  show,
  onClose,
  onConfirm,
  title,
  message,
  rightButtonColor,
}) {
  if (show !== true) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
        </div>
        <div className="modal-message">{message}</div>
        <div className="modal-footer">
          <div className="modal-buttons">
            <button
              type="submit"
              style={{ backgroundColor: rightButtonColor }}
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

MessageModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  rightButtonColor: PropTypes.string,
};

export default MessageModal;
