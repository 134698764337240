import React, { useState } from "react";
import MessageModal from "../Shared/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function ContactUsPage() {
  const [name, setName] = useState("");
  const [first3, setFirst3] = useState("");
  const [next3, setNext3] = useState("");
  const [last4, setLast4] = useState("");
  const [email, setEmail] = useState(null);
  const [textArea, setTextArea] = useState(null);
  const [messageWhenDisabled, setMessageWhenDisabled] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [loadingAfterSubmitting, setLoadingAfterSubmitting] = useState(false);

  const openErrorModal = () => {
    // document.body.style.overflow = "hidden";
    setShowErrorModal(true);
  };

  const closeErrorModal = () => {
    // document.body.style.overflow = "auto";
    setShowErrorModal(false);
  };

  const openSuccessModal = () => {
    // document.body.style.overflow = "hidden";
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    // document.body.style.overflow = "auto";
    setShowSuccessModal(false);
  };

  const openFailureModal = () => {
    // document.body.style.overflow = "hidden";
    setShowFailureModal(true);
  };

  const closeFailureModal = () => {
    // document.body.style.overflow = "auto";
    setShowFailureModal(false);
  };

  // function pause(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  async function submitContactForm() {
    setLoadingAfterSubmitting(true);
    document.body.style.overflow = "hidden";
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => {
      input.disabled = true;
    });
    document.getElementById("textArea").disabled = true;
    const formContainer = document.getElementsByClassName("catering-form")[0];
    formContainer.setAttribute("style", "opacity: 10%;");
    document
      .getElementById("submitCateringInquiryForm")
      .setAttribute("style", "opacity: 10%;");
    document.getElementById("submitCateringInquiryForm").disabled = true;
    // await pause(5000);
    if (
      name.match(/^[a-zA-Z][a-zA-Z-'\s]+$/) &&
      first3.match(/^[0-9]{3}$/) &&
      next3.match(/^[0-9]{3}$/) &&
      last4.match(/^[0-9]{4}$/) &&
      email !== null &&
      textArea !== null
    ) {
      const phoneNumber =
        first3.toString() + next3.toString() + last4.toString();

      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/send-contact-us-form`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          phoneNumber: phoneNumber,
          email: email,
          textArea: textArea,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status_code === 200) {
            openSuccessModal();
            setName("");
            setFirst3("");
            setNext3("");
            setLast4("");
            setEmail(null);
            setTextArea(null);
            document.getElementById("name").value = "";
            document.getElementById("first3").value = "";
            document.getElementById("next3").value = "";
            document.getElementById("last4").value = "";
            document.getElementById("email").value = "";
            document.getElementById("textArea").value = "";
          } else {
            openFailureModal();
          }
        });
    } else {
      setMessageWhenDisabled(
        "Name should only contain letters, spaces, hypens, and apostrophes! Please make sure phone number is valid! All fields should be filled out!",
      );
      openErrorModal();
    }
    document.body.style.overflow = "auto";
    inputFields.forEach((input) => {
      input.disabled = false;
    });
    document.getElementById("textArea").disabled = false;
    formContainer.setAttribute("style", "opacity: 100;");
    document
      .getElementById("submitCateringInquiryForm")
      .setAttribute("style", "opacity: 100%;");
    document.getElementById("submitCateringInquiryForm").disabled = false;
    setLoadingAfterSubmitting(false);
  }

  return (
    <div className="contact-us-page-component">
      <div className="catering-content">
        <div>
          <h2>Get in Touch!</h2>
          <div className="contact-us-page-header-content">
            Have questions or need assistance? Fill out the form below, and
            we’ll respond as soon as possible. You can also find our other
            contact information at the bottom of this and every page. We look
            forward to hearing from you!
          </div>
        </div>
        <div className="catering-form-container">
          {loadingAfterSubmitting && (
            <div className="loading-screen">
              <FontAwesomeIcon className="fa-4x" icon={faSpinner} spin />
            </div>
          )}
          <div className="catering-form">
            <h3 style={{ marginBottom: "0" }}>Contact Us Form</h3>
            <h4 style={{ marginTop: "1vh" }}>
              Please fill out the information below! All fields are required and
              are marked with <sup style={{ color: "red" }}>*</sup>.
            </h4>
            <div className="catering-form-field">
              <div className="catering-form-field-key">
                Name <sup style={{ color: "red" }}>*</sup>
              </div>
              <div className="catering-form-field-value">
                <input
                  style={{ width: "100%" }}
                  type="text"
                  id="name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="catering-form-field">
              <div className="catering-form-field-key">
                Phone Number <sup style={{ color: "red" }}>*</sup>
              </div>
              <div className="catering-form-field-value">
                (
                <input
                  // style={{ width: "12%" }}
                  type="tel"
                  id="first3"
                  onChange={(e) => {
                    setFirst3(e.target.value);
                  }}
                ></input>
                ) -
                <input
                  // style={{ width: "12%" }}
                  type="tel"
                  id="next3"
                  onChange={(e) => {
                    setNext3(e.target.value);
                  }}
                ></input>
                -
                <input
                  // style={{ width: "12%" }}
                  type="tel"
                  id="last4"
                  onChange={(e) => {
                    setLast4(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="catering-form-field">
              <div className="catering-form-field-key">
                Email <sup style={{ color: "red" }}>*</sup>
              </div>
              <div className="catering-form-field-value">
                <input
                  style={{ width: "100%" }}
                  type="email"
                  id="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="catering-form-field">
              <div className="catering-form-field-key">
                Body <sup style={{ color: "red" }}>*</sup>
              </div>
              <div className="catering-form-field-value">
                <textarea
                  id="textArea"
                  style={{ width: "100%" }}
                  rows={3}
                  onChange={(e) => {
                    setTextArea(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="catering-submit-button-container">
            <button
              className="catering-submit-button"
              type="button"
              id="submitCateringInquiryForm"
              value="Submit Form"
              onClick={() => submitContactForm()}
            >
              Contact!
            </button>
          </div>
        </div>
      </div>
      <MessageModal
        show={showErrorModal}
        onClose={closeErrorModal}
        onConfirm={closeErrorModal}
        title={"Error Inputting Fields!"}
        message={messageWhenDisabled}
        rightButtonColor={"blue"}
      />
      <MessageModal
        show={showSuccessModal}
        onClose={closeSuccessModal}
        onConfirm={closeSuccessModal}
        title={"Thanks for contacting us!"}
        message={
          "Message was sent successfully! You will be hearing from us shortly! Please check your email for confirmation (it might be in your spam folder)!"
        }
        rightButtonColor={"blue"}
      />
      <MessageModal
        show={showFailureModal}
        onClose={closeFailureModal}
        onConfirm={closeFailureModal}
        title={"Error!"}
        message={
          "There was a problem sending the message on our side. Please contact us using our provided email or phone number!"
        }
        rightButtonColor={"blue"}
      />
    </div>
  );
}

export default ContactUsPage;
