import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NavBar from "./components/Shared/NavBar.js";
import Home from "./components/Home/Home.js";
import MenuHome from "./components/Menu/Main/MenuHome.js";
import OrderNow from "./components/Order/Main/OrderNow.js";
import IceCreamOrder from "./components/Order/IceCreamOrder/IceCreamOrder.js";
import Cart from "./components/Cart/Cart.js";
import OwnerView from "./components/OwnerView/OwnerView.js";
import ContactUs from "./components/Home/ContactUs.js";
import ProtectedRoutes from "./components/OwnerView/ProtectedRoutes.js";
import { v4 as uuid } from "uuid";
import HomeHeader from "./components/Home/HomeHeader.js";
import SecondaryHeader from "./components/Shared/SecondaryHeader.js";
import IceCreamMenu from "./components/Menu/IceCream/IceCreamMenu.js";
import MenuSelection from "./components/Menu/Main/MenuSelection.js";
import MilkshakesMenu from "./components/Menu/Milkshakes/MilkshakesMenu.js";
import SundaesMenu from "./components/Menu/Sundaes/SundaesMenu.js";
import BobaMenu from "./components/Menu/Boba/BobaMenu.js";
import CoffeeMenu from "./components/Menu/Coffee/CoffeeMenu.js";
import FrappeMenu from "./components/Menu/Frappe/FrappeMenu.js";
import FloatsMenu from "./components/Menu/Floats/FloatsMenu.js";
import SmoothiesMenu from "./components/Menu/Smoothies/SmoothiesMenu.js";
import IndianFlavorsMenu from "./components/Menu/IndianFlavors/IndianFlavorsMenu.js";
import Catering from "./components/Home/Catering.js";
import ContactUsPage from "./components/Home/ContactUsPage.js";

function App() {
  const [featureFlag, setfeatureFlag] = useState(null);
  const [featureFlagLoaded, setFeatureFlagLoaded] = useState(false);
  const [menuItems, setMenuItems] = useState(null);

  const uuidFromUuidV4 = () => {
    const newUuid = uuid();
    sessionStorage.setItem("uuid", newUuid);
  };

  const setMenuItemsHelper = (menuItemsResponse) => {
    setMenuItems(menuItemsResponse);
    sessionStorage.setItem("menuItems", JSON.stringify(menuItemsResponse));
  };

  useEffect(() => {
    async function getFeatureFlag() {
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/get-feature-toggle`)
        .then((response) => response.json())
        .then((response) => {
          setfeatureFlag(response);
          sessionStorage.setItem("featureFlag", response);
          setFeatureFlagLoaded(true);
        });
    }
    async function getAvailableMenuItems() {
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/get-menu`)
        .then((response) => response.json())
        .then((menuItemsResponse) => setMenuItemsHelper(menuItemsResponse));
    }
    if (sessionStorage.getItem("uuid") === null) {
      uuidFromUuidV4();
    }
    getFeatureFlag();
    getAvailableMenuItems();
    // const currentMenuItems = sessionStorage.getItem('menuItems')
    // if (currentMenuItems === null) {
    //     getAvailableMenuItems()
    // } else {
    //     const menuItemsSessionStorage = JSON.parse(sessionStorage.getItem('menuItems'))
    //     setMenuItems(menuItemsSessionStorage)
    // }
  }, []);

  const featureFlagTrue = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/menu",
      element: <MenuHome />,
    },
    {
      path: "/menu/ice-cream",
      element: (
        <MenuSelection
          title={"Scoops of Delight!"}
          description={
            "Customize your perfect ice cream treat by choosing from our wide variety of flavors, including classic favorites and unique creations. Enhance your dessert with a selection of delicious toppings and additional add-ons, with vegan (V), gluten-free (GF), sugar-free (SF), and dairy-free (DF) options available for an indulgent experience tailored to your taste and dietary needs!"
          }
          menuItems={menuItems}
        >
          <IceCreamMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/milkshakes",
      element: (
        <MenuSelection
          title={"Milkshake Magic!"}
          description={
            "Indulge in our delightful milkshake creations, from the fruity Summer Sunset to the rich and decadent Oreo Craze, or the unique blend of peanut butter and chocolate in our Peanut Butter Cup. Feeling creative? Craft your own masterpiece with up to two ice cream flavors, topped with whipped cream!"
          }
          menuItems={menuItems}
        >
          <MilkshakesMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/sundaes",
      element: (
        <MenuSelection
          title={"Sweet Sundae Delights!"}
          description={
            "Treat yourself to our irresistible sundaes, like the Classic Split with a trio of ice cream flavors, or the indulgent Oreo Crumble loaded with cookie goodness. From the gooey Brownie Sundae to the unique Road Kill Sundae, there's something for everyone. Want to customize? Create Your Own sundae with any two scoops!"
          }
          menuItems={menuItems}
        >
          <SundaesMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/boba",
      element: (
        <MenuSelection
          title={"Bubble Tea Bliss!"}
          description={
            "Explore our refreshing boba selection, where each drink combines chewy tapioca pearls with your choice of vibrant flavors. Perfectly crafted to satisfy your cravings, these bubble teas are a delightful treat for any time of day."
          }
          menuItems={menuItems}
        >
          <BobaMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/coffee",
      element: (
        <MenuSelection
          title={"Coffee Corner Classics!"}
          description={
            "Discover our rich and aromatic coffee offerings, expertly brewed to perfection. From smooth lattes to bold espressos, each cup is a comforting treat to complement your favorite ice cream indulgence with sugar-free (SF) options available."
          }
          menuItems={menuItems}
        >
          <CoffeeMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/frappe",
      element: (
        <MenuSelection
          title={"Frappe Delights!"}
          description={
            "Cool down with our delicious frappes, blending smooth ice cream with your favorite flavors and a touch of whipped cream. Each refreshing drink offers a perfect balance of sweetness and creaminess, ideal for a quick treat or a satisfying indulgence."
          }
          menuItems={menuItems}
        >
          <FrappeMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/floats",
      element: (
        <MenuSelection
          title={"Fizzy Float Creations!"}
          description={
            "Dive into our delightful ice cream floats, where creamy scoops meet fizzy soda for a refreshing twist. Each float is crafted to deliver a perfect balance of sweetness and effervescence, making it a fun and satisfying treat."
          }
          menuItems={menuItems}
        >
          <FloatsMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/smoothies",
      element: (
        <MenuSelection
          title={"Smoothie Sensations!"}
          description={
            "Enjoy our vibrant smoothies, blending fresh fruits and creamy textures into a deliciously satisfying drink. Perfectly chilled and bursting with flavor, each smoothie is a refreshing treat for any time of day."
          }
          menuItems={menuItems}
        >
          <SmoothiesMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      path: "/menu/indian-flavors",
      element: (
        <MenuSelection
          title={"Delicious Indian Sweets!"}
          description={
            "Savor our selection of Indian sweets, including rich and creamy kulfi, refreshing falooda, and indulgent gulab jamun. Complement your experience with our vibrant mango ice cream and silky mango lassi for a truly authentic and delightful treat."
          }
          menuItems={menuItems}
        >
          <IndianFlavorsMenu menuItems={menuItems} />
        </MenuSelection>
      ),
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          path: "/admin",
          element: <OwnerView />,
        },
      ],
    },
    {
      path: "/plan-event",
      element: <Catering />,
    },
    {
      path: "/contact-us",
      element: <ContactUsPage />,
    },
  ]);

  const featureFlagFalse = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/order",
      element: <OrderNow />,
    },
    {
      path: "/ice-cream-order/new-order",
      element: <IceCreamOrder />,
    },
    {
      path: "/ice-cream-order/edit-order/:orderId",
      element: <IceCreamOrder />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          path: "/owner-view",
          element: <OwnerView />,
        },
      ],
    },
  ]);

  const router = featureFlag ? featureFlagTrue : featureFlagFalse;

  return (
    <div>
      {featureFlagLoaded && router && (
        <div>
          {window.location.pathname === "/admin" && (
            <RouterProvider router={router} />
          )}
          {window.location.pathname !== "/admin" && (
            <div>
              <NavBar />
              {window.location.pathname === "/" ? (
                <HomeHeader />
              ) : (
                <SecondaryHeader />
              )}
              <div className="condenseScreen">
                <RouterProvider router={router} />
              </div>
              <ContactUs />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
