import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";

function NavBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
      <div className="navbar-component">
        <div className="navbar-logo">
          <a href="/">
            <img
              src="/images/Creamy_Scoops_Logo.jpeg"
              style={{ marginRight: "5px" }}
            ></img>
            <h3 className="navbar-logo-text">Creamy Scoops</h3>
          </a>
        </div>
        <div className="largeScreen">
          {sessionStorage.getItem("featureFlag") ? (
            <ul className="navbar-tabs">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/menu">Menu</a>
              </li>
              <li>
                <a href="/plan-event">Plan Events</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-tabs">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/order">Order</a>
              </li>
              <li>
                <a href="/cart">Cart</a>
              </li>
            </ul>
          )}
        </div>
        <div className="smallScreen">
          <div className="navbar-menu">
            <button onClick={toggleDropdown} className="dropdown-button">
              <FontAwesomeIcon icon={faBars} />
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {sessionStorage.getItem("featureFlag") ? (
                  <ul className="navbar-tabs-dropdown">
                    <li style={{ borderBottom: "1px solid" }}>
                      <a href="/">Home</a>
                    </li>
                    <li style={{ borderBottom: "1px solid" }}>
                      <a href="/menu">Menu</a>
                    </li>
                    <li style={{ borderBottom: "1px solid" }}>
                      <a href="/plan-event">Plan Events</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-tabs-dropdown">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/order">Order</a>
                    </li>
                    <li>
                      <a href="/cart">Cart</a>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
