import React from "react";
import { useState, useEffect } from "react";
import "./MenuHome.css";

function MenuHome() {
  const [promotionalDeals, setPromotionalDeals] = useState(null);
  const [promotionalDealsLoaded, setPromotionalDealsLoaded] = useState(false);
  const [flavorOfTheWeek, setFlavorOfTheWeek] = useState(null);
  const [flavorOfTheWeekLoaded, setFlavorOfTheWeekLoaded] = useState(false);

  useEffect(() => {
    async function getAvailablePromotions() {
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/get-promotional-deals`)
        .then((response) => response.json())
        .then((response) => {
          setPromotionalDeals(response);
          // sessionStorage.setItem('featureFlag', response)
          setPromotionalDealsLoaded(true);
        });
    }

    async function getFlavorOfTheWeek() {
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/get-flavor-of-the-week`)
        .then((response) => response.json())
        .then((response) => {
          setFlavorOfTheWeek(response);
          // sessionStorage.setItem('featureFlag', response)
          setFlavorOfTheWeekLoaded(true);
        });
    }

    getAvailablePromotions();
    getFlavorOfTheWeek();
  }, []);

  return (
    <div>
      <div className="view-menu-component">
        <h2>Explore our Options!</h2>
        <div className="view-menu-content">
          Dive into our delectable menu featuring a wide variety of ice creams,
          sumptuous sundaes, rich coffees, creamy milkshakes, and more.
          Don&apos;t miss out on our upcoming promotional deals designed to make
          your visit even sweeter. Explore the flavors and treats that make
          Creamy Scoops your go-to spot for all things delicious! Go ahead,
          treat yourself – you deserve it!
        </div>
      </div>
      <div className="creamy-scoops-component-menu">
        <h2>View Menu</h2>
        <div className="creamy-scoops-content-menu">
          <a href="menu/ice-cream" className="creamy-scoops-item-menu">
            Ice Cream
          </a>
          <a href="menu/boba" className="creamy-scoops-item-menu">
            Boba
          </a>
          <a href="menu/milkshakes" className="creamy-scoops-item-menu">
            Milkshakes
          </a>
          <a href="menu/sundaes" className="creamy-scoops-item-menu">
            Sundaes
          </a>
          <a href="menu/floats" className="creamy-scoops-item-menu">
            Floats
          </a>
          <a href="menu/smoothies" className="creamy-scoops-item-menu">
            Smoothies
          </a>
          <a href="menu/indian-flavors" className="creamy-scoops-item-menu">
            Indian Flavors
          </a>
          <a href="menu/coffee" className="creamy-scoops-item-menu">
            Coffee
          </a>
          <a href="menu/frappe" className="creamy-scoops-item-menu">
            Frappe
          </a>
        </div>
      </div>
      {promotionalDealsLoaded && promotionalDeals !== null && (
        <div className="promotional-deals-component-menu">
          <h2>Promotional Deals</h2>
          <div className="promotional-deals-content-menu">
            {
              // eslint-disable-next-line no-unused-vars
              promotionalDeals.map((promotionalDeal, key) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="promotional-deal-item-menu">
                    <div style={{ marginBottom: "0.2%" }}>
                      {promotionalDeal.title}
                    </div>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "100",
                        marginBottom: "0.1%",
                      }}
                    >
                      {promotionalDeal.description}
                    </div>
                    <div style={{ fontSize: "0.75rem", fontWeight: "100" }}>
                      {promotionalDeal.expiration_details}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      )}
      {flavorOfTheWeekLoaded && flavorOfTheWeek !== null && (
        <div className="flavor-of-the-week-deals-component-menu">
          <h2>Flavor of the Week</h2>
          <div className="flavor-of-the-week-deals-content-menu">
            {
              // eslint-disable-next-line no-unused-vars
              flavorOfTheWeek.map((flavorOfTheWeek, key) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="flavor-of-the-week-deal-item-menu">
                    <div style={{ marginBottom: "0.2%" }}>
                      {flavorOfTheWeek.title}
                    </div>
                    <div style={{ fontSize: "1rem", fontWeight: "100" }}>
                      {flavorOfTheWeek.description}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuHome;
