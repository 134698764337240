import React from "react";
import PropTypes from "prop-types";
import "./MilkshakesMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const MilkshakesMenu = ({ menuItems }) => {
  const generateMilkshakesMenu = () => {
    return (
      <div className="milkshake-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.milkshakes.map((milkshake, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="milkshake-menu-option">
                <div className="menu-option-label">
                  <div style={{ marginBottom: "5px", fontSize: "large" }}>
                    {milkshake.milkshake}
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    {milkshake.description}
                  </div>
                  <div style={{ fontSize: "small" }}>${milkshake.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const generateFlavorsMenu = () => {
    return (
      <div className="ice-cream-menu-options-container">
        {menuItems.iceCream.flavors.map((flavorData) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-flavors-menu-option">
              <div className="menu-option-label">
                <div>{flavorData.flavorName}</div>
              </div>
              <div className="flavors-menu-option-ingredients-info">
                {generateIngredientsWarning(
                  flavorData.dairyFree,
                  flavorData.glutenFree,
                  flavorData.sugarFree,
                  flavorData.vegan,
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateIngredientsWarning = (df, gf, sf, v) => {
    const ingredientsWarningList = [];
    if (df !== "No") {
      ingredientsWarningList.push("DF");
    }
    if (gf !== "No") {
      ingredientsWarningList.push("GF");
    }
    if (sf !== "No") {
      ingredientsWarningList.push("SF");
    }
    if (v !== "No") {
      ingredientsWarningList.push("V");
    }

    return ingredientsWarningList.join(", ");
  };

  return (
    <div>
      <div>
        <CollapsibleContainerOrder
          formTitle={"Milkshakes"}
          formFunction={generateMilkshakesMenu}
        />
      </div>
      <CollapsibleContainerOrder
        formTitle={"Flavors (Create Your Own)"}
        formFunction={generateFlavorsMenu}
      />
    </div>
  );
};

MilkshakesMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default MilkshakesMenu;
