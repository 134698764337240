import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./ImageGallery.css";

function ImageGallery() {
  const images = [
    "/images/gal_1.JPG",
    "/images/gal_2.JPG",
    "/images/gal_6.JPG",
    "/images/gal_7.JPG",
    "/images/gal_8.JPG",
    "/images/gal_9.JPG",
    "/images/gal_11.JPG",
    "/images/gal_12.JPG",
    // "/images/gal_13.JPG",
    // "/images/gal_15.JPG",
    // "/images/gal_16.JPG",
    // "/images/gal_17.JPG",
    // "/images/gal_18.JPG",
    // "/images/gal_19.JPG",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentImageIndex((nextIndex) =>
      nextIndex === images.length - 1 ? 0 : nextIndex + 1,
    );
  };

  return (
    <div>
      <div className="gallery-component">
        <h2>Gallery</h2>
        <div className="gallery-content">
          <img
            src={images[currentImageIndex]}
            alt={`Image ${currentImageIndex + 1}`}
          />
          <button
            onClick={goToPrevious}
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={goToNext}
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
