import React from "react";
import "./SecondaryHeader.css";

function SecondaryHeader() {
  return (
    <div className="background">
      <div className="secondary-header-component">
        <h1>Creamy Scoops</h1>
      </div>
    </div>
  );
}

export default SecondaryHeader;
