import React, { useState, useEffect } from "react";
import "./Catering.css";
import MessageModal from "../Shared/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Catering() {
  const [name, setName] = useState("");
  const [first3, setFirst3] = useState("");
  const [next3, setNext3] = useState("");
  const [last4, setLast4] = useState("");
  const [email, setEmail] = useState(null);
  const [date, setDate] = useState(null);
  const [numberOfPeople, setNumberOfPeople] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [textArea, setTextArea] = useState(null);
  const [messageWhenDisabled, setMessageWhenDisabled] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [currentDateInputString, setCurrentDateInputString] = useState(null);
  const [futureDateInputString, setFutureDateInputString] = useState(null);
  const [loadingAfterSubmitting, setLoadingAfterSubmitting] = useState(false);

  useEffect(() => {
    const currentDate = new Date(Date.now());
    currentDate.setDate(currentDate.getDate() + 7);
    setCurrentDateInputString(currentDate.toISOString().slice(0, 10));
    const futureDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + 3),
    );
    setFutureDateInputString(futureDate.toISOString().slice(0, 10));
  }, []);

  const openErrorModal = () => {
    // document.body.style.overflow = "hidden";
    setShowErrorModal(true);
  };

  const closeErrorModal = () => {
    // document.body.style.overflow = "auto";
    setShowErrorModal(false);
  };

  const openSuccessModal = () => {
    // document.body.style.overflow = "hidden";
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    // document.body.style.overflow = "auto";
    setShowSuccessModal(false);
  };

  const openFailureModal = () => {
    // document.body.style.overflow = "hidden";
    setShowFailureModal(true);
  };

  const closeFailureModal = () => {
    // document.body.style.overflow = "auto";
    setShowFailureModal(false);
  };

  // function pause(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  async function submitContactForm() {
    setLoadingAfterSubmitting(true);
    document.body.style.overflow = "hidden";
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => {
      input.disabled = true;
    });
    document.getElementById("textArea").disabled = true;
    const formContainer = document.getElementsByClassName("catering-form")[0];
    formContainer.setAttribute("style", "opacity: 10%;");
    document
      .getElementById("submitCateringInquiryForm")
      .setAttribute("style", "opacity: 10%;");
    document.getElementById("submitCateringInquiryForm").disabled = true;
    // await pause(5000);
    if (
      name.match(/^[a-zA-Z][a-zA-Z-'\s]+$/) &&
      first3.match(/^[0-9]{3}$/) &&
      next3.match(/^[0-9]{3}$/) &&
      last4.match(/^[0-9]{4}$/) &&
      email !== null &&
      date !== null &&
      numberOfPeople !== null &&
      eventType !== null &&
      textArea !== null
    ) {
      const phoneNumber =
        first3.toString() + next3.toString() + last4.toString();

      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/send-catering-inquiry`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          phoneNumber: phoneNumber,
          email: email,
          date: date,
          numberOfPeople: numberOfPeople,
          eventType: eventType,
          textArea: textArea,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status_code === 200) {
            openSuccessModal();
            setName("");
            setFirst3("");
            setNext3("");
            setLast4("");
            setEmail(null);
            setDate(null);
            setNumberOfPeople(null);
            setEventType(null);
            setTextArea(null);
            document.getElementById("name").value = "";
            document.getElementById("first3").value = "";
            document.getElementById("next3").value = "";
            document.getElementById("last4").value = "";
            document.getElementById("email").value = "";
            document.getElementById("date").value = "";
            document.getElementById("number").value = "";
            const radios = document.getElementsByName("events-radio");
            radios.forEach((radio) => {
              radio.checked = false;
            });
            document.getElementById("textArea").value = "";
          } else {
            openFailureModal();
          }
        });
    } else {
      setMessageWhenDisabled(
        "Name should only contain letters, spaces, hypens, and apostrophes! Please make sure phone number is valid! All fields should be filled out!",
      );
      openErrorModal();
    }
    document.body.style.overflow = "auto";
    inputFields.forEach((input) => {
      input.disabled = false;
    });
    document.getElementById("textArea").disabled = false;
    formContainer.setAttribute("style", "opacity: 100;");
    document
      .getElementById("submitCateringInquiryForm")
      .setAttribute("style", "opacity: 100%;");
    document.getElementById("submitCateringInquiryForm").disabled = false;
    setLoadingAfterSubmitting(false);
  }

  return (
    <div className="contact-us-main">
      <div className="contact-us-page-component">
        <div className="catering-content">
          <div>
            <h2>Bringing Creamy Scoops to You!</h2>
            <div className="contact-us-page-header-content">
              Make your next event a little sweeter with Creamy Scoops&apos;
              catering services! Whether it&apos;s a birthday party, corporate
              event, or celebration, we bring the joy of ice cream, boba, and
              specialty treats straight to you. Choose from our wide range of
              flavors, toppings, and drinks to create a customized dessert
              experience your guests will love.
            </div>
            <br></br>
            <div className="contact-us-page-header-content">
              Prefer to celebrate at our place? We also offer in-store hosting
              for birthday parties and special events, where you and your guests
              can enjoy a fun, stress-free atmosphere while indulging in our
              delicious treats. Let us handle the sweet details while you enjoy
              the moment!
            </div>
            <br></br>
            <div className="contact-us-page-header-content">
              You can either call us at (803)-431-7148 or fill out the form
              below and we will get back to you!
            </div>
            <br></br>
            <div className="contact-us-page-header-content">
              <b>Note: We need a week advance notice on any events!</b>
            </div>
          </div>
          <div className="catering-form-container">
            {loadingAfterSubmitting && (
              <div className="loading-screen">
                <FontAwesomeIcon className="fa-4x" icon={faSpinner} spin />
              </div>
            )}
            <div className="catering-form">
              <h3 style={{ marginBottom: "0" }}>Event Form</h3>
              <h4 style={{ marginTop: "1vh" }}>
                Please fill out the information below! All fields are required
                and are marked with <sup style={{ color: "red" }}>*</sup>.
              </h4>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Name <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Phone Number <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  (
                  <input
                    // style={{ width: "12%" }}
                    type="tel"
                    id="first3"
                    onChange={(e) => {
                      setFirst3(e.target.value);
                    }}
                  ></input>
                  ) -
                  <input
                    // style={{ width: "12%" }}
                    type="tel"
                    id="next3"
                    onChange={(e) => {
                      setNext3(e.target.value);
                    }}
                  ></input>
                  -
                  <input
                    // style={{ width: "12%" }}
                    type="tel"
                    id="last4"
                    onChange={(e) => {
                      setLast4(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Email <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <input
                    style={{ width: "100%" }}
                    type="email"
                    id="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Date of Event <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <input
                    // style={{ width: "35%" }}
                    type="date"
                    id="date"
                    min={currentDateInputString}
                    max={futureDateInputString}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Number of People Attending{" "}
                  <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <input
                    // style={{ width: "15%" }}
                    type="number"
                    id="number"
                    onChange={(e) => {
                      setNumberOfPeople(e.target.value);
                    }}
                    min="1"
                  ></input>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Event Type <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="events-radio"
                      id="radio-1"
                      onClick={(e) => {
                        setEventType(e.target.value);
                      }}
                      value="Catering"
                    ></input>
                    <label htmlFor="radio-1">Catering</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="events-radio"
                      id="radio-2"
                      onClick={(e) => {
                        setEventType(e.target.value);
                      }}
                      value="Hosting"
                    ></input>
                    <label htmlFor="radio-2">Hosting (Birthdays, etc)</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="events-radio"
                      id="radio-3"
                      onClick={(e) => {
                        setEventType(e.target.value);
                      }}
                      value="Corporate"
                    ></input>
                    <label htmlFor="radio-3">Corporate Events</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="events-radio"
                      id="radio-4"
                      onClick={(e) => {
                        setEventType(e.target.value);
                      }}
                      value="Other"
                    ></input>
                    <label htmlFor="radio-4">Other (Explain Below)</label>
                  </div>
                </div>
              </div>
              <div className="catering-form-field">
                <div className="catering-form-field-key">
                  Body <sup style={{ color: "red" }}>*</sup>
                </div>
                <div className="catering-form-field-value">
                  <textarea
                    id="textArea"
                    style={{ width: "100%" }}
                    rows={3}
                    onChange={(e) => {
                      setTextArea(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="catering-submit-button-container">
              <button
                className="catering-submit-button"
                type="button"
                id="submitCateringInquiryForm"
                value="Submit Form"
                onClick={() => submitContactForm()}
              >
                Contact!
              </button>
            </div>
          </div>
        </div>
        <MessageModal
          show={showErrorModal}
          onClose={closeErrorModal}
          onConfirm={closeErrorModal}
          title={"Error Inputting Fields!"}
          message={messageWhenDisabled}
          rightButtonColor={"blue"}
        />
        <MessageModal
          show={showSuccessModal}
          onClose={closeSuccessModal}
          onConfirm={closeSuccessModal}
          title={"Thanks for contacting us!"}
          message={
            "Message was sent successfully! You will be hearing from us shortly! Please check your email for confirmation (it might be in your spam folder)!"
          }
          rightButtonColor={"blue"}
        />
        <MessageModal
          show={showFailureModal}
          onClose={closeFailureModal}
          onConfirm={closeFailureModal}
          title={"Error!"}
          message={
            "There was a problem sending the message on our side. Please contact us using our provided email or phone number!"
          }
          rightButtonColor={"blue"}
        />
      </div>
    </div>
  );
}

export default Catering;
